import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FragebogenService } from 'src/app/services/fragebogen/fragebogen.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-anmelden',
  templateUrl: './anmelden.component.html',
  styleUrls: ['./anmelden.component.scss'],
})
export class AnmeldenComponent {
  buttonEnabled: boolean = false;
  @ViewChild('tokenInput') tokenInput!: ElementRef<HTMLInputElement>;
  errorMessage: string = '';

  constructor(
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((p) => {
      const params = Object.keys(p);
      if (params.length > 0 && params.includes('token')) {
        this.sessionService.anmelden(p['token']).catch((error) => console.log(error))
      }
    })
  }

  login() {
    if (this.buttonEnabled) {
      this.sessionService.anmelden(this.tokenInput.nativeElement.value)
        .catch((error) => this.errorMessage = error)

    }
  }

  onInput() {
    this.buttonEnabled = this.tokenInput.nativeElement.value.length > 0;
  }
}
