import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-themen',
  templateUrl: './themen.component.html',
  styleUrls: ['./themen.component.scss'],
})
export class ThemenComponent {
  ausgewaehltesModul: any;
  popupAnzeigen: boolean = false;
  module: any[] = [];

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private elementRef: ElementRef
  ) {
    sessionService.getSessionModuleBearbeitet(false).subscribe((data: any) => {
      this.module = data.responseObject;
      if (this.module.length == 0) {
        router.navigateByUrl('/fertig');
      }
    });
  }

  openPopup(modul: any) {
    document
      .querySelectorAll('app-thema-oeffnen[data-anzeigen=true], .popup')
      .forEach((popup: any) => {
        popup.dataset.anzeigen = false;
      });
    this.ausgewaehltesModul = modul;
    this.elementRef.nativeElement.querySelector(
      'app-thema-oeffnen'
    ).dataset.anzeigen = true;
  }

  closePopup() {
    this.elementRef.nativeElement.querySelector(
      'app-thema-oeffnen'
    ).dataset.anzeigen = false;
  }
}
