<h4>Brauchst du eine Pause?</h4>
<p class="modal-text">
  <span>Du arbeitest jetzt schon seit 45 Minuten.</span>
  <span>
    Wenn du eine Pause machen möchtest, kannst du dich später jederzeit wieder
    mit deinem Token einloggen, um den WorXplorer weiter zu bearbeiten.
  </span>
</p>
<div>
  <button class="worxplorer-button" (click)="logout()">
    Speichern & Später wieder kommen
  </button>

  <button class="worxplorer-button" (click)="close()">Weitermachen</button>
</div>
