import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { SessionService } from '../services/session/session.service';
import { FragebogenService } from '../services/fragebogen/fragebogen.service';
import { ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class UebersichtGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sessionService: SessionService,
    private fragebogenService: FragebogenService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (localStorage.getItem('dsgvo') == 'false') {
      return this.router.parseUrl('/begruessung');
    } else if (state.url != '/name' && this.authService.getUsername() == null) {
      return this.router.parseUrl('/name');
    } else if (
      (state.url == '/uebersicht' || state.url == '/') &&
      localStorage.getItem('weiterDialog') != 'false'
    ) {
      this.router.navigateByUrl('/weiter');
    } else if (
      state.url == '/uebersicht' ||
      state.url == '/' ||
      state.url == '/starten' ||
      state.url == '/weiter'
    ) {
      this.sessionService.checkInBearbeitet().subscribe((res: any) => {
        if (localStorage.getItem('fertig') == 'true') {
          this.router.navigateByUrl('/fertig');
        } else if (
          state.url != '/uebersicht' &&
          localStorage.getItem('weiterDialog') == 'false' &&
          res.responseObject
        ) {
          this.router.navigateByUrl('/uebersicht');
        } else if (!res.responseObject && state.url != '/starten') {
          console.log('starten');
          this.router.navigateByUrl('/starten');
        }

        this.sessionService
          .getSessionModuleBearbeitet(false)
          .subscribe((res: any) => {
            if (res.responseObject.length == 0) {
              this.router.navigateByUrl('/fertig');
              localStorage.setItem('usernameBetonen', 'false');
              localStorage.setItem('weiterDialog', 'false');
            }
          });
      });
    } else if (state.url == '/name' && this.authService.getUsername() != null) {
      this.router.navigateByUrl('/starten');
    } else if (
      state.url != '/weiter' &&
      localStorage.getItem('weiterDialog') == 'true'
    ) {
      this.router.navigateByUrl('/weiter');
    } else if (
      state.url != '/uebersicht' &&
      localStorage.getItem('username') != null &&
      localStorage.getItem('weiterDialog') == 'false' &&
      localStorage.getItem('fertig') == 'false'
    ) {
      this.router.navigateByUrl('/uebersicht');
    }
    return true;
  }

  canActivateChild() { }
}
