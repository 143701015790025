import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input('uebersicht') uebersicht: boolean = true;
  @Input('modul') modul: any;
  @Input('anzahlModulAufgaben') anzahlModulAufgabe: number = 0;
  @Input('aktuelleModulAufgabe') aktuelleModulAufgabe: number = 0;
  @Input('anmelden') anmelden: boolean = false;
  @Input('fazit') fazit: boolean = false;

  constructor(private authService: AuthService, private router: Router) {}

  impressum() {
    window.open('/impressum', '_blank')?.focus();
  }

  uebersichtAnzeigen() {
    this.router.navigateByUrl('/uebersicht');
  }

  fazitAnzeigen() {
    this.router.navigateByUrl('/abschliessen');
  }

  logout() {
    var popup = document.querySelector<HTMLElement>('app-speichern-popup');
    if (popup) {
      document
        .querySelectorAll('app-thema-oeffnen[data-anzeigen=true]')
        .forEach((popup: any) => {
          popup.dataset.anzeigen = false;
        });
      popup.dataset['anzeigen'] = 'true';
    }
  }
}
