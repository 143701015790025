import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-kategorisieren',
  templateUrl: './kategorisieren.component.html',
  styleUrls: ['./kategorisieren.component.scss'],
})
export class KategorisierenComponent {
  modul: any;
  modulId: number = 0;
  zuordnungen: any[] = [];
  ausgewaehlteKarte: any;
  ausgewaehlteKarteZuordnung: any;
  buttonEnabled: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router
  ) {
    document.querySelector('html')?.classList.remove('dialog');
  }

  ngOnInit() {
    this.loadModulId();
    this.sessionService.getModul(this.modulId).subscribe((data: any) => {
      this.modul = data.responseObject;
      document.querySelector('.intro')!.innerHTML = this.replaceAnreden(
        this.modul.schiebereglerIntro
      );
      this.sessionService
        .getSessionZuordnungen(this.modulId)
        .subscribe((res: any) => {
          this.zuordnungen = res.responseObject;
          this.zuordnungen.forEach((kz: any) => {
            var karte = document.getElementById(kz.karte.kennung);
            karte!.dataset['punkte'] = kz.schieberegler;
            var spalte = document.querySelector(
              `[data-zuordnung="${kz.dragAndDrop}"]`
            );
            var ablage = spalte?.querySelectorAll('.ablage')[kz.position - 1];
            this.moveKarte(karte, spalte, ablage);
          });
          this.pruefeAlleAufgabenBearbeitet();
        });
    });
  }


  private loadModulId() {
    const _modulId = this.route.snapshot.parent?.paramMap.get("modulId");
    if (!_modulId) {
      throw new Error("Modul ID nicht vorhanden!");
    }
    this.modulId = Number(_modulId);
  }

  replaceAnreden(text: string) {
    var spanTemplate = "<span class='text-unterstrichen'>ANREDE</span>";
    var neuText = text.replace('Job', spanTemplate.replace('ANREDE', 'Job'));
    return neuText;
  }

  moveKarte(karte: any, spalte: any, targetAblage: any) {
    var naechsteFreieAblage = Array.from(
      spalte.querySelectorAll('.ablage')
    ).filter((a) => this.isAblageFrei(a))[0];

    var karteNichtInSpalte =
      Array.from(spalte.querySelectorAll('app-mdoul-karte')).filter(
        (k: any) => k.id === karte?.id
      ).length === 0;

    if (targetAblage && this.isAblageFrei(targetAblage)) {
      targetAblage.appendChild(karte);
      karte.dataset['zugeordnet'] = 'true';
    } else if (
      naechsteFreieAblage !== null &&
      naechsteFreieAblage instanceof HTMLDivElement &&
      karteNichtInSpalte
    ) {
      naechsteFreieAblage.appendChild(karte);
      karte.dataset['zugeordnet'] = 'true';
    }
  }

  isAblageFrei(ablage: any) {
    return ablage.children.length === 0;
  }

  openKarte(karte: any) {
    this.ausgewaehlteKarte = karte;
    this.ausgewaehlteKarteZuordnung = this.zuordnungen.find(
      (zuordnung: any) => zuordnung.karte.id == karte.id
    );
    var schieberegler = document.querySelector(
      'app-schieberegler'
    ) as HTMLElement;
    document.querySelector('html')?.classList.add('dialog');
    schieberegler.dataset['visible'] = 'true';
  }

  pruefeAlleAufgabenBearbeitet() {
    this.buttonEnabled =
      this.zuordnungen.filter((zu: any) => zu.schieberegler == -1).length == 0;
  }

  setzePunkte(punkte: any, karte: any) {
    document.getElementById(karte.kennung)!.dataset['punkte'] = punkte;
    this.pruefeAlleAufgabenBearbeitet();
  }

  themaBeenden() {
    if (this.buttonEnabled) {
      this.router.navigateByUrl('/uebersicht');
    }
  }
  zurueck() {
    this.router.navigateByUrl(`/modul/${this.modulId}/bewerten`);
  }
}
