<div class="erledigte-themen">
  <h5 [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }">
    <span>Erledigte Themen</span>
  </h5>

  <div
    [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
    style="padding-left: 24px"
  >
    <span class="ergaenzung">
      Wenn du ein Thema erneut bearbeiten möchtest, klicke es einfach an.
    </span>
  </div>

  <div class="container">
    <app-thema-oeffnen
      [modul]="ausgewaehltesModul"
      data-anzeigen="false"
      (schliessenEvent)="closePopup()"
    ></app-thema-oeffnen>
    <div class="row">
      <div class="column mr">
        <erledigtes-thema
          [modul]="module[0]"
          [attr.data-bearbeitet]="module[0] != null"
          (click)="modulDialogOeffnen(module[0])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
      <div class="column mr ml">
        <erledigtes-thema
          [modul]="module[1]"
          [attr.data-bearbeitet]="module[1] != null"
          (click)="modulDialogOeffnen(module[1])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
      <div class="column ml">
        <erledigtes-thema
          [modul]="module[2]"
          [attr.data-bearbeitet]="module[2] != null"
          (click)="modulDialogOeffnen(module[2])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
    </div>
    <div class="row">
      <div class="column mr">
        <erledigtes-thema
          [modul]="module[3]"
          [attr.data-bearbeitet]="module[3] != null"
          (click)="modulDialogOeffnen(module[3])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
      <div class="column ml mr vc">
        <div
          class="haken"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        >
          <svg
            width="80"
            height="80"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="16"
              height="16"
              rx="8"
              fill="url(#paint0_linear_623_10658)"
            ></rect>
            <path
              d="M6.29974 11.8997L2.56641 8.16641L3.28307 7.44974L6.29974 10.4664L12.6997 4.06641L13.4164 4.78307L6.29974 11.8997Z"
              fill="white"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_623_10658"
                x1="8"
                y1="0"
                x2="8"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#EEEADF"></stop>
                <stop offset="1" stop-color="#FAF8F5"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div class="column ml">
        <erledigtes-thema
          [modul]="module[4]"
          [attr.data-bearbeitet]="module[4] != null"
          (click)="modulDialogOeffnen(module[4])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
    </div>
    <div class="row">
      <div class="column mr">
        <erledigtes-thema
          [modul]="module[5]"
          [attr.data-bearbeitet]="module[5] != null"
          (click)="modulDialogOeffnen(module[5])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
      <div class="column ml mr vc">
        <erledigtes-thema
          [modul]="module[6]"
          [attr.data-bearbeitet]="module[6] != null"
          (click)="modulDialogOeffnen(module[6])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
      <div class="column ml">
        <erledigtes-thema
          [modul]="module[7]"
          [attr.data-bearbeitet]="module[7] != null"
          (click)="modulDialogOeffnen(module[7])"
          [ngClass]="{ 'skeleton-box': !datenAnzeigen || module.length == 0 }"
        ></erledigtes-thema>
      </div>
    </div>
  </div>
</div>
