<app-header [isCoach]="false"></app-header>
<div class="container">
  <div class="row">
    <div class="column">
      <img
        src="/assets/img/Coach-Bild.png"
        alt=""
        style="margin: 70px 0 32px 0"
      />
    </div>
  </div>
  <div class="row">
    <div class="column">
      <h2><span>D</span><span>u hast es geschafft!</span></h2>
    </div>
  </div>
  <div class="row" style="margin-top: 48px">
    <div class="column ml mr">
      <p style="width: 845px">
        Vielen Dank, dass du den WorXplorer bearbeitet hast. Ich hoffe, es war
        spannend für dich und hat dir Spaß gemacht. Wenn es dir gefallen hat,
        erzähl deinen Freunden und Bekannten gerne von uns. Ich habe jetzt
        alles, was wir von dir brauche, um deinen Ergebnisbericht zu erstellen.
        Den bekommst du im deinem persönlichen Auswertungsgespräch. Schreib mir
        danach gerne an info@worxplorer.de, wie dir das Gespräch gefallen hat
        und wie es für dich beruflich weiter gegangen ist. Ich bin gespannt, auf
        deine Erfahrungen und wünsche dir schon jetzt alles Gute!
      </p>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <button
        class="worxplorer-button"
        style="margin-top: 80px"
        (click)="buttonClicked()"
      >
        Beenden
        <svg
          style="margin-left: 8px"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 16L6.95 14.925L13.125 8.75H0V7.25H13.125L6.95 1.075L8 0L16 8L8 16Z"
            fill="#262231"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</div>
<app-footer [anmelden]="false" [fazit]="true" [uebersicht]="false"></app-footer>
