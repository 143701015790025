<app-header [modul]="'Check Out'"></app-header>
<div class="container">
  <div class="row" style="margin-bottom: 12px">
    <div class="column ml-30 mr-30 p-0">
      <span class="intro light">
        Bevor du die Bearbeitung des WorXplorers vollständig abschließt, überleg
        einmal, was du heute schon für dich und deine Frage mitnimmst.
      </span>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <app-notiz-block
        [notizBlock]="fragebogen?.checkOut?.notizBlock"
        [maxZeichenAnzahl]="100"
        (minAnzahlErreicht)="blockFertig($event)"
        [notizen]="abschliessenNotizen"
        [intro]="'Was nimmst du heute schon mit?'"
        data-tall="true"
      ></app-notiz-block>
    </div>
  </div>
  <div class="row" style="margin-top: 16px">
    <div class="column mr">
      <button class="worxplorer-button ml-20" (click)="zurueck()">
        <span>
          <svg
            style="margin-right: 11px"
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.975 11.95L0 5.975L5.975 0L7.05 1.075L2.9 5.225H18V6.725H2.9L7.05 10.875L5.975 11.95Z"
              fill="#262231"
            ></path>
          </svg>
          Zurück
        </span>
      </button>
    </div>
    <div class="column ml">
      <button class="worxplorer-button mr-20" (click)="abschied()">
        <span>
          Weiter
          <svg
            class="arrow"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16L6.95 14.925L13.125 8.75H0V7.25H13.125L6.95 1.075L8 0L16 8L8 16Z"
              fill="#262231"
            ></path>
          </svg>
        </span>
      </button>
    </div>
  </div>
</div>
<app-footer [uebersicht]="false"></app-footer>
