import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FragebogenService } from 'src/app/services/fragebogen/fragebogen.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-abschliessen',
  templateUrl: './abschliessen.component.html',
  styleUrls: ['./abschliessen.component.scss'],
})
export class AbschliessenComponent {
  abschliessenNotizen: any[] = [];
  fragebogen: any = null;
  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private router: Router,
    private fragebogenService: FragebogenService
  ) {
    fragebogenService.getFragebogen().then((fragebogen: any) => {
      this.fragebogen = fragebogen;
      sessionService
        .getSessionNotizen(fragebogen.checkOut.notizBlock.id)
        .subscribe((res: any) => {
          this.abschliessenNotizen = this.initNotizen(
            fragebogen.checkOut.notizBlock,
            res.responseObject
          );
        });
    });
  }

  initNotizen(notizBlock: any, dbNotizen: any) {
    var notizen = [];
    for (let i = 0; i < notizBlock.maxKarten; i++) {
      notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenString: this.authService.getToken(),
      });
    }
    return notizen;
  }

  abschied() {
    this.sessionService.abschliessen().subscribe((res: any) => {
      if (res.responseObject) {
        this.router.navigateByUrl('/abschied');
      }
    });
  }

  zurueck() {
    this.router.navigateByUrl('/uebersicht');
  }

  blockFertig(event: any) {}
}
