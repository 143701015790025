import { Component, HostListener, OnInit } from '@angular/core';
import { ModulCheckInComponent } from './modul-check-in/modul-check-in.component';
import { BewertenComponent } from './bewerten/bewerten.component';
import { KategorisierenComponent } from './kategorisieren/kategorisieren.component';
import { MdoulKarteComponent } from './mdoul-karte/mdoul-karte.component';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-modul',
  templateUrl: './modul.component.html',
  styleUrls: ['./modul.component.scss'],
})
export class ModulComponent implements OnInit {
  modul: any = {};
  aktuelleModulAufgabe: number = 0;
  anzahlModulAufgaben: number = 3;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.sessionService
        .getModul(Number(params['modulId']))
        .subscribe((data: any) => {
          this.modul = data.responseObject;
        });
    });
  }

  @HostListener('document: click', ['$event'])
  onBodyClick(event: any) {
    if (!event.target.closest('app-mdoul-karte')) {
      var karteDialog = document.querySelector(
        'app-mdoul-karte[data-dialog="true"]'
      );
      if (karteDialog && karteDialog instanceof HTMLElement) {
        karteDialog.dataset['dialog'] = 'false';
        karteDialog.style.transform = '';
        document.querySelector('html')?.classList.remove('dialog');
      }
      this.schliesseKarte();
    }
    if (
      document.querySelector("app-schieberegler[data-visible='true']") &&
      !event.target.closest('app-mdoul-karte') &&
      !event.target.closest('app-schieberegler')
    ) {
      (
        document.querySelector(
          "app-schieberegler[data-visible='true']"
        ) as HTMLElement
      ).dataset['visible'] = 'false';
      document.querySelector('html')?.classList.remove('dialog');
    }
  }

  schliesseKarte() {}

  onOutletLoaded(component: any) {
    if (component instanceof ModulCheckInComponent) {
      this.aktuelleModulAufgabe = 1;
    } else if (component instanceof BewertenComponent) {
      this.aktuelleModulAufgabe = 2;
    } else if (component instanceof KategorisierenComponent) {
      this.aktuelleModulAufgabe = 3;
    } else {
      this.aktuelleModulAufgabe = 0;
    }
  }
}
