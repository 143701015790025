import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  logout() {
    localStorage.setItem('tokenPresent', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.clear();
  }

  login(token: any) {
    console.log('LOGIN', token);

    if (token == null) {
      return false;
    }
    localStorage.setItem(
      'dsgvo',
      (token.status.toLowerCase() == 'geoeffnet_und_dsgvo').toString()
    );
    localStorage.setItem('tokenPresent', 'true');
    localStorage.setItem('token', token.tokenString);
    return true;
  }

  isLoggedIn() {
    return localStorage.getItem('tokenPresent') != null;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  setUsername(name: string) {
    localStorage.setItem('username', name);
  }
}
