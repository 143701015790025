import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UebersichtComponent } from './views/uebersicht/uebersicht.component';
import { FertigComponent } from './views/uebersicht/rechte-seite/fertig/fertig.component';
import { StartenComponent } from './views/uebersicht/rechte-seite/starten/starten.component';
import { NameComponent } from './views/uebersicht/rechte-seite/name/name.component';
import { WeiterComponent } from './views/uebersicht/rechte-seite/weiter/weiter.component';
import { ThemenComponent } from './views/uebersicht/rechte-seite/themen/themen.component';
import { CheckInComponent } from './views/check-in/check-in.component';
import { ModulCheckInComponent } from './views/modul/modul-check-in/modul-check-in.component';
import { KategorisierenComponent } from './views/modul/kategorisieren/kategorisieren.component';
import { BewertenComponent } from './views/modul/bewerten/bewerten.component';
import { ModulComponent } from './views/modul/modul.component';
import { AnmeldenComponent } from './views/anmelden/anmelden.component';
import { TokenGuardGuard } from './guards/token-guard.guard';
import { UebersichtGuard } from './guards/uebersicht.guard';
import { AbschliessenComponent } from './views/abschliessen/abschliessen.component';
import { BegruessungComponent } from './views/begruessung/begruessung.component';
import { AbschiedComponent } from './views/abschied/abschied.component';
import { ImperssumComponent } from './views/imperssum/imperssum.component';
import { DatenschutzComponent } from './views/datenschutz/datenschutz.component';
import { TokenUsedComponent } from './views/anmelden/token-used/token-used.component';
import { CheckInResolver } from './check-in.resolver';

const routes: Routes = [
  {
    path: 'anmelden',
    component: AnmeldenComponent,
  },
  {
    path: 'tokenUsed',
    component: TokenUsedComponent,
  },
  {
    path: 'begruessung',
    component: BegruessungComponent,
    canActivate: [TokenGuardGuard],
  },
  {
    path: 'abschied',
    component: AbschiedComponent,
    canActivate: [TokenGuardGuard],
  },
  {
    path: '',
    component: UebersichtComponent,
    canActivate: [TokenGuardGuard, UebersichtGuard],
    resolve: { data: CheckInResolver },
    children: [
      {
        path: 'fertig',
        component: FertigComponent,
        // canActivate: [UebersichtGuard],
      },
      {
        path: 'name',
        component: NameComponent,
        // canActivate: [UebersichtGuard],
      },
      {
        path: 'starten',
        component: StartenComponent,
        // canActivate: [UebersichtGuard],
      },
      {
        path: 'weiter',
        component: WeiterComponent,
        // canActivate: [UebersichtGuard],
      },
      {
        path: 'uebersicht',
        component: ThemenComponent,
        // canActivate: [UebersichtGuard],
      },
    ],
  },
  {
    path: 'checkIn',
    component: CheckInComponent,
    canActivate: [TokenGuardGuard],
  },
  {
    path: 'abschliessen',
    component: AbschliessenComponent,
    canActivate: [TokenGuardGuard],
  },
  {
    path: 'modul/:modulId',
    component: ModulComponent,
    canActivate: [TokenGuardGuard],
    children: [
      {
        path: 'checkIn',
        component: ModulCheckInComponent,
      },
      {
        path: 'kategorisieren',
        component: KategorisierenComponent,
      },
      {
        path: 'bewerten',
        component: BewertenComponent,
      },
    ],
  },
  {
    path: 'impressum',
    component: ImperssumComponent,
  },
  {
    path: 'dsgvo',
    component: DatenschutzComponent,
    canActivate: [TokenGuardGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
