import { Component } from '@angular/core';

@Component({
  selector: 'app-token-used',
  templateUrl: './token-used.component.html',
  styleUrls: ['./token-used.component.scss']
})
export class TokenUsedComponent {

}
