<div class="container fusstext">
  <div class="row">
    <div class="column vc" *ngIf="uebersicht">
      <div
        class="mr link popup-event"
        (click)="logout()"
        style="margin-left: 24px"
      >
        {{
          anmelden
            ? "Später wieder kommen"
            : !fazit
            ? "Speichern & Später wieder kommen"
            : undefined
        }}
      </div>
    </div>
    <div class="column vc" *ngIf="!uebersicht && !anmelden && !fazit">
      <div class="mr ml-20 link" (click)="uebersichtAnzeigen()">
        <span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.975 17.95L3 11.975L8.975 6L10.05 7.075L5.9 11.225H21V12.725H5.9L10.05 16.875L8.975 17.95Z"
              fill="black"
            ></path>
          </svg>
          Zurück zur Themenübersicht
        </span>
      </div>
    </div>
    <div class="column vc" *ngIf="fazit">
      <div class="mr ml-20 link" (click)="fazitAnzeigen()">
        <span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.975 17.95L3 11.975L8.975 6L10.05 7.075L5.9 11.225H21V12.725H5.9L10.05 16.875L8.975 17.95Z"
              fill="black"
            ></path>
          </svg>
          Fazit
        </span>
      </div>
    </div>
    <div class="column vc ml mr" *ngIf="modul != null">
      <div class="fortschritt">
        <div class="balken-wrapper">
          <div
            class="balken"
            *ngFor="let item of [].constructor(3); index as i"
            [ngClass]="{ aktiv: i < aktuelleModulAufgabe }"
          ></div>
        </div>
        <span>{{ aktuelleModulAufgabe }}/{{ anzahlModulAufgabe }}</span>
      </div>
    </div>
    <div class="column ml vc">
      <div class="link mr-20" (click)="impressum()">Impressum</div>
    </div>
  </div>
</div>
