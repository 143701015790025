import { Component } from '@angular/core';

@Component({
  selector: 'app-imperssum',
  templateUrl: './imperssum.component.html',
  styleUrls: ['./imperssum.component.scss']
})
export class ImperssumComponent {

}
