<div class="content-wrapper">
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="column">
          <h4>Check In: Wo startest du heute?</h4>
        </div>
      </div>
      <div class="row">
        <div class="column" style="margin: 36px 0 24px 0">
          <span
            >Der WorXplorer kann dir helfen, besser zu verstehen, was dir bei
            der Arbeit wichtig ist, für dich einzuordnen, was du in der
            aktuellen Situation angehen möchtest und worauf du bei anstehenden
            beruflichen Entscheidungen achten kannst. Das geht umso besser, je
            klarer dir ist, worum es dir gerade geht. Nimm dir daher einen
            Augenblick Zeit, um für dich festzuhalten, warum du den WorXplorer
            heute bearbeiten möchtest und was du dir davon erhoffst.</span
          >
        </div>
      </div>
      <div class="row">
        <div class="column" style="margin-top: 24px">
          <button class="worxplorer-button" (click)="worxplorerStarten()">
            Starten
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
