<div class="container">
  <div class="row" style="margin-bottom: 12px">
    <div class="column ml-30 mr-30 p-0">
      <span class="intro light">{{ modul?.modulCheckIn?.intro }}</span>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <app-notiz-block
        [notizBlock]="modul?.modulCheckIn?.notizBlock1"
        [notizen]="block1Notizen"
        (minAnzahlErreicht)="blockFertig(1, $event)"
      ></app-notiz-block>
    </div>

    <div class="column">
      <app-notiz-block
        [notizBlock]="modul?.modulCheckIn?.notizBlock2"
        [notizen]="block2Notizen"
        (minAnzahlErreicht)="blockFertig(2, $event)"
      ></app-notiz-block>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <div class="button-wrapper">
        <button
          class="worxplorer-button mr-20"
          data-tooltip="Bitte gib bei jeder Frage mindestens eine Antwort, damit du fortfahren kannst."
          [ngClass]="{ inaktiv: !buttonEnabled }"
          (click)="bewerten()"
        >
          Weiter gehts
        </button>
      </div>
    </div>
  </div>
</div>
