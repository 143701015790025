<div class="content-wrapper">
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="column">
          <span>
            Du hast alle Fragen bearbeitet. Schau dir noch einmal links deine
            wichtigste Frage an, um dein abschließendes Fazit zu ziehen. Falls
            du vorher noch etwas ändern möchtest, kannst du die bereits
            erledigten Themen nochmals überarbeiten.
          </span>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <button class="worxplorer-button" (click)="abschliessen()">
            Abschliessen & Fazit ziehen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
