import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { FragebogenService } from 'src/app/services/fragebogen/fragebogen.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'rechte-seite',
  templateUrl: './rechte-seite.component.html',
  styleUrls: ['./rechte-seite.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class RechteSeiteComponent {
  bearbeiteteModule: any[] = [];
  fragebogenModule: any[] = [];
  ueberschriftAnzeigen: boolean = false;
  @Input('ueberschrift') ueberschrift: string = '';
  zusatztext: string = '';

  constructor(
    private sessionServie: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private fragebogenService: FragebogenService
  ) {
    router.events.subscribe((val: any) => {
      this.getBearbeitetModule();
    });
    fragebogenService
      .getFragebogen()
      .then((f: any) => (this.fragebogenModule = f.module));
    this.getBearbeitetModule();
  }

  getBearbeitetModule() {
    this.sessionServie
      .getSessionModuleBearbeitet(true)
      .subscribe((res: any) => {
        this.bearbeiteteModule = res.responseObject;
        this.ermittelUeberschrift();
      });
  }

  ermittelUeberschrift() {
    this.ueberschriftAnzeigen = localStorage.getItem('weiterDialog') == 'false';
    if (localStorage.getItem('username') == null) {
      this.ueberschrift = '';
      this.zusatztext = '';
    } else if (this.bearbeiteteModule.length == 0) {
      this.ueberschrift = 'Mit welchem Thema möchtest du starten?';
      this.zusatztext =
        'Klick das Thema an, das du als erstes bearbeiten möchtest und erfahre mehr darüber';
      localStorage.setItem('fertig', 'false');
    } else if (this.bearbeiteteModule.length == this.fragebogenModule.length) {
      this.ueberschrift = 'Du hast alle Themen bearbeitet!';
      this.zusatztext = '';
      localStorage.setItem('fertig', 'true');
    } else {
      this.zusatztext = '';
      localStorage.setItem('fertig', 'false');
      this.ueberschrift = 'Mit welchem Thema möchtest du weitermachen?';
    }
  }
}
