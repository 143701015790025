import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

export type SpeichernPopupComponentVariant = 'speichernUndWiederkommen' | 'speichern'
@Component({
  selector: 'app-speichern-popup',
  templateUrl: './speichern-popup.component.html',
  styleUrls: ['./speichern-popup.component.scss'],
})
export class SpeichernPopupComponent {
  @ViewChild('popup') popup!: ElementRef<HTMLInputElement>;
  @Input("variant") variant: SpeichernPopupComponentVariant = "speichernUndWiederkommen"
  readonly variants = {
    speichern: {
      header: "Später wieder kommen",
      content: `\
Wenn du jetzt auf Okay klickst wirst du abgemeldet.

Möchtest du zu einem späteren Zeitpunkt weitermachen?`
    },
    speichernUndWiederkommen: {
      header: "Speichern und Später wieder kommen",
      content: `\
Wenn du jetzt auf Okay klickst, werden deine bisherigen Antworten gespeichert und du wirst abgemeldet.

Möchtest du die Bearbeitung des WorXplorers jetzt unterbrechen?`
    }
  }

  constructor(private authService: AuthService, private router: Router, private elRef: ElementRef) { }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/anmelden');
  }

  cancel() {
    console.log(this.elRef)
    this.elRef.nativeElement.setAttribute("data-anzeigen", false);
  }

  get header() {
    return this.variants[this.variant].header;
  }

  get content() {
    return this.variants[this.variant].content.split('\n');
  }
}
