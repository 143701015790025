import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-begruessung',
  templateUrl: './begruessung.component.html',
  styleUrls: ['./begruessung.component.scss'],
})
export class BegruessungComponent {
  dsgvoAccepted: boolean = false;
  buttonEnabled: boolean = false;

  constructor(private router: Router, private sessionService: SessionService) {
    sessionService.getStatus().subscribe((res: any) => {
      if (res.responseObject) {
        this.dsgvoAccepted =
          res.responseObject.toLowerCase() == 'geoeffnet_und_dsgvo';
      }
    });
  }

  los() {
    if (this.buttonEnabled || this.dsgvoAccepted) {
      this.sessionService.acceptDsgvo().subscribe((res: any) => {
        if (res.responseObject) {
          localStorage.setItem('dsgvo', 'true');
          this.router.navigateByUrl('/');
        }
      });
    }
  }

  onInput(event: any) {
    this.buttonEnabled = event.target.checked;
  }

  openDsgvo() {
    window.open('/dsgvo', '_blank')?.focus();
  }

  logout() {
    var popup = document.querySelector<HTMLElement>('app-speichern-popup');
    if (popup) {
      document
        .querySelectorAll('app-thema-oeffnen[data-anzeigen=true]')
        .forEach((popup: any) => {
          popup.dataset.anzeigen = false;
        });
      popup.dataset['anzeigen'] = 'true';
    }
  }
}
