import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-single-notize',
  templateUrl: './single-notize.component.html',
  styleUrls: ['./single-notize.component.scss'],
})
export class SingleNotizeComponent {
  notizen: any[] = [];
  @Input('notizBlock') public notizBlock: any = {};
  @Input() initialAnzeigen: boolean = false;
  @Input() maxZeichenAnzahl: number = 100;
  @Output('notizAngelegt') notizAngelegt = new EventEmitter<boolean>();

  constructor(private sessionService: SessionService) {}

  ngOnChanges() {
    if (this.notizen.length == 0 && this.notizBlock != null) {
      this.sessionService
        .getSessionNotizen(this.notizBlock.id)
        .subscribe((data: any) => {
          this.initNotizen(data.responseObject);
        });
    }
  }

  public replaceAnreden(text: string) {
    var spanTemplate = "<span class='text-unterstrichen'>ANREDE</span>";
    var neuText = text.replace(
      'eine Frage',
      spanTemplate.replace('ANREDE', 'eine Frage')
    );
    return neuText;
  }

  initNotizen(dbNotizen: any[]) {
    for (let i = 0; i < this.notizBlock?.maxKarten; i++) {
      this.notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: this.notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenId: 123,
      });
    }
  }

  emitEvent() {
    this.notizAngelegt.emit(true);
  }
}
