<div class="content">
  <div class="color"></div>
  <div class="text" (click)="oeffneKarte($event)">
    <span class="karte-text" [hidden]="!notize?.text || !notize?.text.trim()">
      {{ notize.text }}
    </span>
    <span [hidden]="notize?.text && notize?.text.trim()"
      ><svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.25 14V7.75H0V6.25H6.25V0H7.75V6.25H14V7.75H7.75V14H6.25Z"
          fill="#FFBE27"
        ></path>
      </svg>
    </span>
  </div>

  <div class="karte-bearbeiten-content">
    <div class="zeichenangabe">
      <span>{{ aktuelleZeichenAnzahl }}/{{ maxZeichenAnzahl }} Zeichen</span>
    </div>
    <input
      #eingabe
      class="eingabe"
      [value]="notize.text"
      [placeholder]="intro"
      (input)="aktualisiereZeichenAnzahl()"
      (keypress)="keyPressed($event)"
    />
    <div class="buttons">
      <button
        *ngIf="loeschenAllowed"
        class="verwerfen worxplorer-button"
        (click)="schliesseKarte(false, true)"
      >
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 16.5H11.5V4.5H2.5V16.5ZM0.25 2.5V1H3.6L4.6 0H9.4L10.4 1H13.75V2.5H0.25ZM2.5 18C2.1 18 1.75 17.85 1.45 17.55C1.15 17.25 1 16.9 1 16.5V3H13V16.5C13 16.9 12.85 17.25 12.55 17.55C12.25 17.85 11.9 18 11.5 18H2.5ZM2.5 16.5H11.5H2.5Z"
            fill="#262231"
          ></path>
        </svg>
      </button>
      <button
        class="anpinnen worxplorer-button"
        [ngClass]="{ inaktiv: !anpinnenErlaubt }"
        (click)="schliesseKarte(true, false)"
      >
        Anpinnen
      </button>
    </div>
  </div>
</div>
