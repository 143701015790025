import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-starten',
  templateUrl: './starten.component.html',
  styleUrls: ['./starten.component.scss'],
})
export class StartenComponent {
  constructor(private router: Router) {}

  worxplorerStarten() {
    this.router.navigateByUrl('/checkIn');
  }
}
