<h4>
  {{ header }}
</h4>
<div *ngFor="let zeile of content">
  <p>{{ zeile }}</p>
</div>
<div style="display: flex; justify-content: space-around">
  <button class="worxplorer-button" (click)="logout()">Okay</button>
  <button class="worxplorer-button" (click)="cancel()">Abbrechen</button>
</div>
