<app-schieberegler
  [karte]="ausgewaehlteKarte"
  [zuordnung]="ausgewaehlteKarteZuordnung"
  data-visible="false"
  [kategorien]="modul?.schiebereglerKategorie"
  (karteBearbeitet)="setzePunkte($event, ausgewaehlteKarte)"
></app-schieberegler>
<div class="container">
  <div class="row">
    <div class="column ml-30 mr-30 p-0">
      <span class="intro"> </span>
    </div>
  </div>
  <div class="row">
    <div class="column ml-30 p-0">
      Um deine Einschätzung vorzunehmen, klick einfach auf jede der Karten.
    </div>
  </div>
  <div class="row">
    <div class="column">
      <div class="container block">
        <app-mdoul-karte
          *ngFor="let karte of modul?.karten"
          [id]="karte.kennung"
          data-zugeordnet="false"
          data-dialog="false"
          (click)="openKarte(karte)"
          [karte]="karte"
          [isSchieberegler]="true"
          data-punkte="-1"
          [karteZurodnung]="zuordnungen"
        ></app-mdoul-karte>
        <div class="row">
          <div
            class="column spalte"
            *ngFor="let item of [].constructor(5); index as i"
            [attr.data-zuordnung]="i + 1"
          >
            <div class="container">
              <div class="row">
                <div class="ablage"></div>
              </div>
              <div class="row">
                <div class="ablage"></div>
              </div>
              <div class="row">
                <div class="ablage"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 16px">
    <div class="column mr">
      <button class="worxplorer-button ml-20" (click)="zurueck()">
        <span>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.975 11.95L0 5.975L5.975 0L7.05 1.075L2.9 5.225H18V6.725H2.9L7.05 10.875L5.975 11.95Z"
              fill="#262231"
            ></path>
          </svg>
          Zurück
        </span>
      </button>
    </div>
    <div class="column ml">
      <button
        class="worxplorer-button mr-20"
        [ngClass]="{ inaktiv: !buttonEnabled }"
        (click)="themaBeenden()"
        data-tooltip="Bitte schätze alle Karten ein, damit du fortfahren kannst."
      >
        <span>
          Thema beenden
          <svg
            class="arrow"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16L6.95 14.925L13.125 8.75H0V7.25H13.125L6.95 1.075L8 0L16 8L8 16Z"
              fill="#262231"
            ></path>
          </svg>
        </span>
      </button>
    </div>
  </div>
</div>
