import { Component, Input } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'wichtigste-frage',
  templateUrl: './wichtigste-frage.component.html',
  styleUrls: ['./wichtigste-frage.component.scss'],
})
export class WichtigsteFrageComponent {
  anzeigen: boolean = false;
  wichtigsteFrageText: string = '';

  constructor(private sessionService: SessionService) {
    sessionService.getSessionWichtigsteFrage().subscribe((data: any) => {
      this.wichtigsteFrageText = data.responseObject[0]?.text;
      this.anzeigen = data.responseObject[0]?.text;
    });
  }
}
