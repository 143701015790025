<div class="container">
  <div class="row">
    <div class="column">
      <img
        src="/assets/img/logo.svg"
        alt=""
        style="margin-top: 72px; margin-bottom: 46px; height: 86px"
      />
    </div>
  </div>
  <div class="row">
    <div class="column" style="margin: 12px 0 16px 0">
      <p>
        du hast den WorXplorer vollständig bearbeitet. Deine Ergebnisse erfährst
        du von deinem Coach. Falls du Fragen zum weiteren Prozess hast, wende
        dich am besten an die Person, die dir die Einladung geschickt hat.
      </p>
      <p>
        Hast du darüber hinaus Fragen zum WorXplorer oder ein Feedback für uns?
        Dann schreib uns gerne eine Mail an info@worxplorer.de
      </p>
      <p>Vielen Dank und alles Gute!</p>
    </div>
  </div>
</div>
<app-footer [anmelden]="true"></app-footer>
