import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-weiter',
  templateUrl: './weiter.component.html',
  styleUrls: ['./weiter.component.scss'],
})
export class WeiterComponent {
  constructor(private router: Router) {}

  buttonClicked() {
    localStorage.setItem('weiterDialog', 'false');
    this.router.navigateByUrl('/uebersicht');
  }
}
