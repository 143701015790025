import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FragebogenService } from 'src/app/services/fragebogen/fragebogen.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent {
  frage: any = null;
  wichtigsteFrageAnzeigen: boolean = false;
  notizBlock1Fertig: boolean = false;
  notizBlock2Fertig: boolean = false;
  wichtigsteFrageFertig: boolean = false;
  notizBlock1Notizen: any[] = [];
  notizBlock2Notizen: any[] = [];
  wichtigsteFrageNotizen: any[] = [];

  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private router: Router,
    private fragebogenService: FragebogenService
  ) {
    fragebogenService.getFragebogen().then((fragebogen: any) => {
      this.frage = fragebogen;
      sessionService
        .getSessionNotizen(fragebogen.checkIn.notizBlock1.id)
        .subscribe((res: any) => {
          console.log('CHECKIN', res);

          this.notizBlock1Notizen = this.initNotizen(
            fragebogen.checkIn.notizBlock1,
            res.responseObject
          );
          this.notizBlock1Fertig =
            this.notizBlock1Notizen.filter(
              (notiz: any) => notiz.text?.length > 0
            ).length >= fragebogen.checkIn.notizBlock1.minKarten;
          this.wichtigsteFrageDarstellen();
        });

      sessionService
        .getSessionNotizen(fragebogen.checkIn.notizBlock2.id)
        .subscribe((res: any) => {
          this.notizBlock2Notizen = this.initNotizen(
            fragebogen.checkIn.notizBlock2,
            res.responseObject
          );
          this.notizBlock2Fertig =
            this.notizBlock2Notizen.filter(
              (notiz: any) => notiz.text?.length > 0
            ).length >= fragebogen.checkIn.notizBlock2.minKarten;
          this.wichtigsteFrageDarstellen();
        });

      sessionService
        .getSessionNotizen(fragebogen.checkIn.wichtigsteFrage.id)
        .subscribe((res: any) => {
          this.wichtigsteFrageNotizen = this.initNotizen(
            fragebogen.checkIn.wichtigsteFrage,
            res.responseObject
          );
          this.wichtigsteFrageFertig =
            this.wichtigsteFrageNotizen.filter(
              (notiz: any) => notiz.text?.length > 0
            ).length >= fragebogen.checkIn.wichtigsteFrage.minKarten;
          this.wichtigsteFrageDarstellen();
        });
    });
  }

  initNotizen(notizBlock: any, dbNotizen: any) {
    var notizen = [];
    for (let i = 0; i < notizBlock.maxKarten; i++) {
      notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenString: this.authService.getToken(),
      });
    }
    return notizen;
  }

  wichtigsteFrageDarstellen() {
    const checkInAbschliessenErlaubt = this.wichtigsteFrageAnzeigen;
    this.wichtigsteFrageAnzeigen =
      this.notizBlock1Fertig && this.notizBlock2Fertig;
    if (
      this.wichtigsteFrageFertig &&
      this.notizBlock1Fertig &&
      this.notizBlock2Fertig &&
      checkInAbschliessenErlaubt
    ) {
      localStorage.setItem('weiterDialog', 'true');
      localStorage.setItem('usernameBetonen', 'false');
      this.fragebogenService.ladeCheckInBearbeitet();
      this.router.navigateByUrl('/weiter');
    }
  }

  blockFertig(block: number, fertig: boolean) {
    if (block == 1) {
      this.notizBlock1Fertig = fertig;
    } else {
      this.notizBlock2Fertig = fertig;
    }
    if (this.wichtigsteFrageAnzeigen) {
      this.wichtigsteFrageAnzeigen =
        this.notizBlock1Fertig && this.notizBlock2Fertig;
    }
  }
}
