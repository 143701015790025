import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FragebogenService {
  fragebogen: any = null;
  checkInBearbeitet = false;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.ladeFragebogen(authService.getToken() as string);
  }

  async ladeFragebogen(token: string) {
    var response: any = await this
      .getSessionFragebogen(token)
      .toPromise();
    this.fragebogen = response.responseObject;

    // this.sessionService.getSessionFragebogen(token).subscribe((res: any) => {
    //   this.fragebogen = res.responseObject;
    // });
  }

  getSessionFragebogen(token: any) {
    return this.http.get(`/api/fragebogenSession?token=${token}`);
  }

  async ladeCheckInBearbeitet() {
    var isBearbeitet = await this.callCheckInBearbeitet()
      .toPromise();
    console.log(isBearbeitet);

    this.callCheckInBearbeitet().subscribe((res: any) => {
      this.checkInBearbeitet = res.responseObject;
    });
  }

  isCheckInBearbeitet() {
    if (this.checkInBearbeitet == false) {
      this.ladeCheckInBearbeitet();
    }
    return this.checkInBearbeitet;
  }

  callCheckInBearbeitet() {
    return this.http.get(
      `/api/fragebogenSession/checkInBearbeitet?token=${this.authService.getToken()}`
    );
  }

  async getFragebogen() {
    if (this.fragebogen == null) {
      await this.ladeFragebogen(this.authService.getToken() as string);
    }
    return this.fragebogen;
  }
}
