import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TimeoutPopupComponent } from './timeout-popup/timeout-popup.component';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private modalRef?: BsModalRef;
  private inactivityTimer: any;

  constructor(private modalService: BsModalService) {}

  startInactivityTimer() {
    var popup = document.querySelector<HTMLElement>('app-timeout-popup');
    if (popup && popup.dataset['show'] == 'false') {
      this.stopInactivityTimer(); // Stopping any existing timer

      this.inactivityTimer = setTimeout(() => {
        this.showInactivityPopup();
      }, 1000 * 60 * 40); // 40 Minuten (in Millisekunden)
    }

    // window.addEventListener('mousemove', this.resetInactivityTimer.bind(this));
    // window.addEventListener('keydown', this.resetInactivityTimer.bind(this));
  }

  stopInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = null;
    }

    // window.removeEventListener(
    //   'mousemove',
    //   this.resetInactivityTimer.bind(this)
    // );
    // window.removeEventListener('keydown', this.resetInactivityTimer.bind(this));
  }

  closePopup() {
    var popup = document.querySelector<HTMLElement>('app-timeout-popup');
    if (popup) {
      popup.dataset['show'] = 'false';
    }
  }

  resetInactivityTimer() {
    this.startInactivityTimer();
  }

  private showInactivityPopup() {
    var popup = document.querySelector<HTMLElement>('app-timeout-popup');
    console.log(popup);
    if (popup) {
      popup.dataset['show'] = 'true';
    }
  }

  private hideInactivityPopup() {
    var popup = document.querySelector<HTMLElement>('app-timeout-popup');
    if (popup) {
      popup.dataset['show'] = 'false';
    }

    // if (this.modalRef) {
    //   this.modalRef.hide();
    //   this.modalRef = undefined;
    // }
  }
}
