import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { FragebogenService } from '../fragebogen/fragebogen.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public fragebogen: any;
  public bearbeiteteModule: any[] = [];
  public unbearbeiteteModule: any[] = [];
  public notizen: any[] = [];
  public zuordnungen: any[] = [];

  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private fragebogenService: FragebogenService) { }

  async anmelden(token: string) {
    return new Promise((resolve, reject) => {

      this.http.get(`/api/fragebogenSession/anmelden?token=${token}`)
        .subscribe(
          (data: any) => {
            if (this.authService.login(data.responseObject)) {
              this.router.navigateByUrl('/begruessung');
              this.fragebogenService.ladeFragebogen(
                token
              );
              this.fragebogenService.ladeCheckInBearbeitet();
              resolve(true)
            } else {
              reject(
                'Anmeldung nicht möglich!'
              );

            }
          },
          (err: any) => {
            if (err.status === 403) {
              reject("Du hast den WorXplorer bereits vollständig bearbeitet. Wende dich bei Fragen gerne an deinen Coach oder schreib uns eine Nachricht an info@worxplorer.de.")
            }
            if (err.error.tokenAlreadyUsed) {
              reject(this.router.navigateByUrl('/tokenUsed'));
            } else {
              reject(
                err.error.message
              )
            }
          }
        );
    })
  }

  abschliessen() {
    return this.http.put(
      `/api/fragebogenSession/tokenBearbeitet?token=${this.authService.getToken()}`,
      {}
    );
  }

  acceptDsgvo() {
    return this.http.put(
      `/api/fragebogenSession/dsgvo?token=${this.authService.getToken()}`,
      {}
    );
  }

  getStatus() {
    return this.http.get(
      `/api/fragebogenSession/status?token=${this.authService.getToken()}`
    );
  }



  getSessionModuleBearbeitet(bearbeitet: boolean) {
    return this.http.get(
      `/api/fragebogenSession/module/filter?bearbeitet=${bearbeitet}&token=${this.authService.getToken()}`
    );
  }

  getSessionNotizen(notizBlock: number) {
    return this.http.get(
      `/api/fragebogenSession/notizen?notizBlock=${notizBlock}&token=${this.authService.getToken()}`
    );
  }

  getSessionWichtigsteFrage() {
    return this.http.get(
      `/api/fragebogenSession/notizen/wichtigsteFrage?token=${this.authService.getToken()}`
    );
  }

  updateSessionNotiz(notiz: any) {
    return this.http.put(
      `/api/fragebogenSession/notizen/${notiz.id
      }?token=${this.authService.getToken()}`,
      notiz
    );
  }

  getSessionZuordnungen(modulId: any) {
    return this.http.get(
      `/api/fragebogenSession/zuordnungen/${modulId}?token=${this.authService.getToken()}`
    );
  }

  saveZuordnung(zuordnung: any) {
    return this.http.post(
      `/api/fragebogenSession/zuordnungen?token=${this.authService.getToken()}`,
      zuordnung
    );
  }

  //TEST
  getDimension(dimensionId: any) {
    return this.http.get(
      `/api/fragebogenSession/dimensionen/${dimensionId}?token=${this.authService.getToken()}`
    );
  }

  //TEST
  getModul(modulId: any) {
    return this.http.get(
      `/api/fragebogenSession/module/${modulId}?token=${this.authService.getToken()}`
    );
  }

  postNotize(notizBlockId: any, notiz: any) {
    return this.http.post(
      `/api/fragebogenSession/notizBloecke/${notizBlockId}/notizen?token=${this.authService.getToken()}`,
      notiz
    );
  }

  checkInBearbeitet() {
    return this.http.get(
      `/api/fragebogenSession/checkInBearbeitet?token=${this.authService.getToken()}`
    );
  }

  // sortiereModule() {
  //   if (this.notizen.length > 0 && this.zuordnungen.length > 0) {
  //     this.sortiereBearbeiteteModule();
  //   }
  // }

  // sortiereBearbeiteteModule() {
  //   this.bearbeiteteModule = this.fragebogen.module.filter((modul: any) => {
  //     var bearbeitet = true;
  //     if (modul.modulCheckIn) {
  //       bearbeitet = this.pruefeModulCheckIn(modul.modulCheckIn);
  //     }
  //     if (bearbeitet) {
  //       bearbeitet = this.modulKartenBearbeitet(modul.karten);
  //     }
  //     return bearbeitet;
  //   });
  //   this.unbearbeiteteModule = this.fragebogen.module.filter((modul: any) =>
  //     this.bearbeiteteModule.find((m: any) => m.id != modul.id)
  //   );
  // }

  // modulKartenBearbeitet(karten: any[]) {
  //   var bearbeiteteKarten = this.zuordnungen.filter((zuordnung: any) => {
  //     if (karten.find((k: any) => k.id == zuordnung.karteId)) {
  //       return zuordnung.dragAndDrop > 0 && zuordnung.schieberegler > -1;
  //     }
  //     return false;
  //   });
  //   return bearbeiteteKarten.length == karten.length;
  // }

  // pruefeModulCheckIn(modulCheckIn: any) {
  //   return (
  //     this.pruefeNotizBlock(modulCheckIn.notizBlock1) &&
  //     this.pruefeNotizBlock(modulCheckIn.notizBlock2)
  //   );
  // }

  // pruefeNotizBlock(notizBlock: any) {
  //   var bearbeiteteNotizen = this.notizen.filter((notiz: any) => {
  //     if (notiz.notizBlockId == notizBlock.id) {
  //       return notiz.text?.trim().length > 0;
  //     }
  //     return false;
  //   });
  //   return bearbeiteteNotizen.length >= notizBlock.minKarten;
  // }
}
