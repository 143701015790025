<div class="content-wrapper">
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="column">
          <h4>Jetzt geht’s los!</h4>
        </div>
      </div>
      <div class="row">
        <div class="column" style="margin: 36px 0 24px 0">
          <span>Wie möchtest du gerne von mir angesprochen werden?</span>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <input
            #nameInput
            class="name-input"
            type="text"
            name="name"
            value=""
          />
        </div>
      </div>
      <div class="row">
        <div class="column" style="margin-top: 36px">
          <button class="worxplorer-button" (click)="worxplorerStarten()">
            Worxplorer Starten
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
