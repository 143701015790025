import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-bewerten',
  templateUrl: './bewerten.component.html',
  styleUrls: ['./bewerten.component.scss'],
})
export class BewertenComponent implements OnInit {
  public modul: any;
  buttonAktiv: boolean = false;
  modulId: number = 0;
  zuordnungen: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {
    document.querySelector('html')?.classList.remove('dialog');
  }

  ngOnInit() {
    this.loadModulId();
    this.sessionService.getModul(this.modulId).subscribe((data: any) => {
      this.modul = data.responseObject;
      document.querySelector('.intro')!.innerHTML = this.replaceAnreden(
        this.modul.ordnungsIntro
      );
      this.sessionService
        .getSessionZuordnungen(this.modulId)
        .subscribe((res: any) => {
          this.zuordnungen = res.responseObject;
        });
    });
  }


  private loadModulId() {
    const _modulId = this.route.snapshot.parent?.paramMap.get("modulId");
    if (!_modulId) {
      throw new Error("Modul ID nicht vorhanden!");
    }
    this.modulId = Number(_modulId);
  }

  replaceAnreden(text: string) {
    var spanTemplate = "<span class='text-unterstrichen'>ANREDE</span>";
    var neuText = text.replace('dir', spanTemplate.replace('ANREDE', 'dir'));
    neuText = neuText.replace('dich', spanTemplate.replace('ANREDE', 'dich'));
    neuText = neuText.replace(
      'deiner',
      spanTemplate.replace('ANREDE', 'deiner')
    );

    return neuText;
  }

  kategorisieren() {
    if (this.buttonAktiv) {
      this.router.navigateByUrl(`/modul/${this.modulId}/kategorisieren`);
    }
  }
  zurueck() {
    this.router.navigateByUrl(`/modul/${this.modulId}/checkIn`);
  }
}
