import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';
import { InactivityService } from '../../inactivity.service';
@Component({
  selector: 'app-uebersicht',
  templateUrl: './uebersicht.component.html',
  styleUrls: ['./uebersicht.component.scss'],
})
export class UebersichtComponent {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private inactivityService: InactivityService
  ) {}

  ngOnInit() {
    this.inactivityService.startInactivityTimer();
  }

  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keydown', ['$event'])
  onUserActivity(event: MouseEvent | KeyboardEvent) {
    this.inactivityService.resetInactivityTimer();
  }

  @HostListener('document: click', ['$event'])
  onBodyClick(event: any) {
    if (
      !event.target.closest('app-thema-oeffnen') &&
      !event.target.closest('erledigtes-thema') &&
      !event.target.closest('app-bearbeiten-thema') &&
      !event.target.closest('.popup') &&
      !event.target.closest('.popup-event')
    ) {
      document
        .querySelectorAll('app-thema-oeffnen')
        .forEach((dialog: any) => (dialog.dataset.anzeigen = false));

      document
        .querySelectorAll('.popup')
        .forEach((dialog: any) => (dialog.dataset.anzeigen = false));
    }
  }
}
