import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
})
export class NameComponent {
  @ViewChild('nameInput') nameInput!: ElementRef<HTMLInputElement>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private sessionService: SessionService
  ) { }
  worxplorerStarten() {
    this.authService.setUsername(this.nameInput.nativeElement.value);
    this.sessionService.checkInBearbeitet().subscribe((res: any) => {
      if (res.responseObject) {
        localStorage.setItem('usernameBetonen', 'false');

        this.sessionService.getSessionModuleBearbeitet(false).subscribe((moduleRes: any) => {
          if (moduleRes.responseObject.length === 0) {
            localStorage.setItem('weiterDialog', 'false');
            this.router.navigateByUrl('/uebersicht');
          } else {
            localStorage.setItem('weiterDialog', 'true');
            this.router.navigateByUrl('/weiter');
          }
        });
      } else {
        this.router.navigateByUrl('/starten');
      }
    });
  }
}
